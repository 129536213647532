import React from 'react';
import Head from 'next/head';
import { HeroSkeleton } from '~/components/shared/Skeleton';
import MirHero from '~/components/sections/MIR Hero';
import ACP_Banner from '~/components/sections/ACP Banner';
import WholeWifiModule from '~/components/sections/Whole Home WiFi';
import EducationModule from '~/components/sections/Education Module';
import Banner from '~/components/sections/US News';
import ValueProps from '~/components/sections/Value Props';
import FinalEpq from '~/components/sections/Final EPQ';
import { getFilteredComponentName } from '~/helpers/utils/getFilteredComponentName';
import { MonarchPreampPlacement } from '@redventures/cohesion-utils-react';
import { useEffect, useContext } from 'react';
import { CohesionContext } from '@redventures/cohesion-utils-react';
import { logError } from '@red-digital/bricks';
import LogRocket from 'logrocket';

const Home = () => {
  const cohesionData = useContext(CohesionContext);
  useEffect(() => {
    // Set preamp & monarch response in LogRocket
    window?.cohesion('monarch:done', () => {
      try {
        LogRocket.track('tracking', {
          audienceName: cohesionData.result.source.trafficFlow.value.name || 0,
        });
      } catch (error) {
        logError(error);
      }
    });
  }, []);
  return (
    <>
      <Head>
        <title>Hughesnet® Internet | 1-855-386-1643 | Satellite Internet</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Hughesnet® is America's #1 choice for satellite internet. Save big with Award-Winning Hughesnet packages. Plus Free standard installation at no extra cost!"
        />
      </Head>

      {/* Hero Placement */}
      <MonarchPreampPlacement
        placement="4VdUdUM1j7gFHXes7NqVPF"
        fallback={HeroSkeleton}
        componentId={getFilteredComponentName}
      >
        <MirHero />
      </MonarchPreampPlacement>

      {/* Banner Below Hero Placement */}
      <MonarchPreampPlacement
        placement="10P4eZlEej5Awmgo2YUGiC"
        componentId={getFilteredComponentName}
      >
        <ValueProps />
      </MonarchPreampPlacement>

      {/* Section 1 Placement */}
      <MonarchPreampPlacement
        placement="2TvlZ5qJXwIaUwILfRrflF"
        componentId={getFilteredComponentName}
      >
        <ACP_Banner />
      </MonarchPreampPlacement>

      {/* Section 2 Placement */}
      <MonarchPreampPlacement
        placement="6APp9Tgx9kgvtT9Jzirp35"
        componentId={getFilteredComponentName}
      >
        <WholeWifiModule />
      </MonarchPreampPlacement>

      {/* Section 3 Placement */}
      <MonarchPreampPlacement
        placement="3ETuJls1gI4Mkd5hdbr6aL"
        componentId={getFilteredComponentName}
      >
        <EducationModule />
      </MonarchPreampPlacement>

      {/* Section 4 Placement */}
      <MonarchPreampPlacement
        placement="1hEJvSU7FGLiN7OF21b6Sn"
        componentId={getFilteredComponentName}
      >
        <Banner />
      </MonarchPreampPlacement>

      {/* Section 5 Placement */}
      <MonarchPreampPlacement
        placement="50oFaqrrR5LUqnMZrcVinK"
        componentId={getFilteredComponentName}
      >
        <FinalEpq />
      </MonarchPreampPlacement>

      {/* Section 6 Placement */}
      <MonarchPreampPlacement
        placement="7FKlHEBWSnMJwN30vK4Dcb"
        componentId={getFilteredComponentName}
      />

      {/* Section 7 Placement */}
      <MonarchPreampPlacement
        placement="1aNLmdEcqxidyMp6SwfjVj"
        componentId={getFilteredComponentName}
      />

      {/* Section 8 Placement */}
      <MonarchPreampPlacement
        placement="5MSDldnvFD5Y0SgQbIGQNZ"
        componentId={getFilteredComponentName}
      />

      {/* Return Visitor Modal */}
      <MonarchPreampPlacement
        placement="1WxHXlKAqe23e3dysil9KS"
        componentId={getFilteredComponentName}
      />
    </>
  );
};

export default Home;
