import React, { useRef } from 'react';
import styles from './ValueProps.module.scss';
import Typography from '~/components/shared/Typography';
import {
  ConferenceRebrand,
  ConnectRebrand,
  GamingRebrand,
  StreamRebrand,
  TrophyRebrand,
  PersonRebrand,
  UnlimitedRebrand,
  DollarRebrand,
  PlayRebrand,
  MapRebrand,
  SatelliteRebrand,
  HouseRebrand,
  ContractRebrand,
  OperatorRebrand,
  ThumbsUp,
  Wifi,
  Unlimited,
} from '~/components/icons';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import LegalAnnotation from '~/components/shared/LegalAnnotation';
import PhoneNumber from '~/components/shared/Fuse/PhoneNumber';
import { useMonarchPreampPlacementContext } from '@redventures/cohesion-utils-react';
import Button from '~/components/shared/Button';

const BASE_DATA = {
  backgroundColor: 'white',
  disclaimer1: '*100 Mbps speeds available on select plans in select areas.',
  disclaimer2: '*100 Mbps speeds available on select plans in select areas.',
  disclaimer3: '*100 Mbps speeds available on select plans in select areas.',
  disclaimerToggle1: false,
  disclaimerToggle2: false,
  disclaimerToggle3: false,
  headline: 'Now you can do it all, wherever you live',
  subhead: '',
  icon1: 'thumbsup',
  icon2: 'play',
  icon3: 'trophy',
  iconColor: '#0091c4',
  prop1Subhead1:
    'Hughesnet is faster than ever, with download speeds up to 100 Mbps',
  prop1Subhead1Legal: 'generic-speed',
  prop1Subhead1LegalToggle: true,
  prop1Subhead2: 'and includes a Wi-Fi Modem featuring Wi-Fi 6 technology.',
  prop1Subhead2Legal: 'change to legal key',
  prop1Subhead2LegalToggle: false,
  prop1Subhead2Toggle: true,
  prop2Subhead1: 'Every plan comes with unlimited data,',
  prop2Subhead1Legal: 'data-limit',
  prop2Subhead1LegalToggle: true,
  prop2Subhead2:
    "so you'll never get cut off or pay overage fees. Stream HD video and music, and do video-conferencing.",
  prop2Subhead2Legal: 'change to legal key',
  prop2Subhead2LegalToggle: false,
  prop2Subhead2Toggle: true,
  prop3Subhead1:
    'Only the Hughesnet Fusion plan combines the reach of satellite with the responsiveness of low-latency wireless, so you can enjoy gaming and playing online like never before.',
  prop3Subhead1Legal: 'change to legal key',
  prop3Subhead1LegalToggle: false,
  prop3Subhead2: 'change',
  prop3Subhead2Legal: 'change to legal key',
  prop3Subhead2LegalToggle: false,
  prop3Subhead2Toggle: false,
  propHeading1:
    'Connect: Experience a fast and reliable connection wherever you live.',
  propHeading1Legal: 'change to legal key',
  propHeading1LegalToggle: false,
  propHeading2: 'Stream: Watch more video and do more video-conferencing',
  propHeading2Legal: 'change to legal key',
  propHeading2LegalToggle: false,
  propHeading3: 'Play: Enjoy online gaming with a low-latency Fusion plan',
  propHeading3Legal: 'change to legal key',
  propHeading3LegalToggle: false,
  showCTA: false,
  ctaCopy: 'Upgrade Now',
};

export default function ValueProps() {
  const { data: monarchData } = useMonarchPreampPlacementContext();
  const valuePropsSection = useRef();

  const observeSections = useIntersectionObserver(
    [valuePropsSection],
    (el) =>
      fireElementViewedEvent(
        'value props',
        'value props section',
        'value props',
        headline
      ),
    undefined,
    // Fire tagular only when the full element is visible
    { threshold: 1.0 }
  );
  const data = { ...BASE_DATA, ...monarchData };
  const {
    backgroundColor,
    headlineTextColor,
    icon1,
    icon2,
    icon3,
    header3TextColor,
    headline,
    propHeading1,
    propHeading2,
    propHeading3,
    disclaimer1,
    disclaimerToggle1,
    disclaimer2,
    disclaimerToggle2,
    disclaimer3,
    disclaimerToggle3,

    propHeading1LegalToggle,
    propHeading1Legal,
    propHeading2LegalToggle,
    propHeading2Legal,
    propHeading3LegalToggle,
    propHeading3Legal,
    prop1Subhead1,
    prop1Subhead1LegalToggle,
    prop1Subhead1Legal,
    prop1Subhead2Toggle,
    prop1Subhead2,
    prop1Subhead2LegalToggle,
    prop1Subhead2Legal,
    prop2Subhead1,
    prop2Subhead1LegalToggle,
    prop2Subhead1Legal,
    prop2Subhead2Toggle,
    prop2Subhead2,
    prop2Subhead2LegalToggle,
    prop2Subhead2Legal,
    prop3Subhead1,
    prop3Subhead1LegalToggle,
    prop3Subhead1Legal,
    prop3Subhead2Toggle,
    prop3Subhead2,
    prop3Subhead2LegalToggle,
    prop3Subhead2Legal,
    showPermalease,
    permaleaseColor,
    showCTA,
    ctaCopy,
    subhead,
  } = data;

  const props = [];
  //Amount of props that are editable
  const amountProps = 3;
  //Loop through the metadata for each prop and assign them on each object
  for (let i = 1; i <= amountProps; i++) {
    props.push({
      header: data[`propHeading${i}`],
      headerLegal: data[`propHeading${i}Legal`],
      headerLegalToggle: data[`propHeading${i}LegalToggle`],
      subhead1: data[`prop${i}Subhead1`],
      subhead1LegalToggle: data[`prop${i}Subhead1LegalToggle`],
      subhead1Legal: data[`prop${i}Subhead1Legal`],
      subhead2Toggle: data[`prop${i}Subhead2Toggle`],
      subhead2: data[`prop${i}Subhead2`],
      subhead2LegalToggle: data[`prop${i}Subhead2LegalToggle`],
      subhead2Legal: data[`prop${i}Subhead2Legal`],
      disclaimerToggle: data[`disclaimerToggle${i}`],
      disclaimer: data[`disclaimer${i}`],
      icon: data[`icon${i}`],
    });
  }

  const getIcon = (icon) => {
    switch (icon) {
      case 'conference':
        return <ConferenceRebrand />;
      case 'dish':
        return <ConnectRebrand />;
      case 'gaming':
        return <GamingRebrand />;
      case 'play':
        return <StreamRebrand />;
      case 'trophy':
        return <TrophyRebrand />;
      case 'unlimited':
        return <UnlimitedRebrand />;
      case 'dollar':
        return <DollarRebrand />;
      case 'person':
        return <PersonRebrand />;
      case 'stream':
        return <PlayRebrand />;
      case 'map':
        return <MapRebrand />;
      case 'satellite':
        return <SatelliteRebrand />;
      case 'house':
        return <HouseRebrand />;
      case 'contract':
        return <ContractRebrand />;
      case 'operator':
        return <OperatorRebrand />;
      case 'thumbsup':
        return <ThumbsUp />;
      case 'wifi':
        return <Wifi />;
      case 'newUnlimited':
        return <Unlimited />;
      default:
        return <ConferenceRebrand />;
    }
  };

  return (
    <section
      ref={valuePropsSection}
      id="upgrade"
      className={styles.container}
      style={{ backgroundColor: backgroundColor }}
    >
      <Typography variant="h2" className={styles.headline}>
        <span>{headline}</span>
      </Typography>
      <Typography variant="h2" className={styles.subhead}>
        <span>{subhead}</span>
      </Typography>
      <div className={styles.contentWrapper}>
        {props.map((prop, index) => (
          <div key={index}>
            {getIcon(prop?.icon)}
            <div className={styles.content}>
              <Typography variant="h3" className={styles.heading}>
                <span dangerouslySetInnerHTML={{ __html: prop?.header }} />
                {prop?.headerLegalToggle && (
                  <LegalAnnotation annotation={prop?.headerLegal} />
                )}
              </Typography>
              <Typography variant="p" className={styles.subheading}>
                <span dangerouslySetInnerHTML={{ __html: prop?.subhead1 }} />
                {prop?.subhead1LegalToggle && (
                  <LegalAnnotation annotation={prop?.subhead1Legal} />
                )}{' '}
                {prop?.subhead2Toggle && (
                  <span dangerouslySetInnerHTML={{ __html: prop?.subhead2 }} />
                )}
                {prop?.subhead2LegalToggle && (
                  <LegalAnnotation annotation={prop?.subhead2Legal} />
                )}
              </Typography>
              {prop?.disclaimerToggle && (
                <Typography variant="disclaimer" className={styles.disclaimer}>
                  <span>{prop?.disclaimer}</span>
                </Typography>
              )}
            </div>
          </div>
        ))}
      </div>
      {showCTA && (
        <div className={styles.buttons}>
          <Button
            href="/order-online"
            tracking={{
              position: 'ACP BANNER EPQ',
              location: 'ACP BANNER',
              elementType: 'BUTTON',
              text: 'Check Availablity',
              actionOutcome: 'Redirect to Cart',
            }}
            fuse
            className={`${styles.btn}`}
          >
            {ctaCopy}
          </Button>
        </div>
      )}
    </section>
  );
}
